import React from 'react';
import Image from 'mui-image';
import { Box, Button, Typography, Stack } from '@mui/material';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { SiAppstore } from 'react-icons/si';
import { LuGlobe2 } from 'react-icons/lu';

const urls = {
  desktop: 'https://app.youwho.io',
  // desktop: 'https://why.youwho.io',
  ios: 'https://ios.youwho.io',
  android: 'https://android.youwho.io'
};

export default function HomePage() {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bgcolor: 'inherit',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/static/media/img/png/youwho-splash-banner.png)'
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          width: { xs: '80%' },
          maxWidth: '550px',
          //   height: '40%',
          py: '70px',
          // background: 'white.50',
          textAlign: 'center',
          borderRadius: '20px',
          top: '20%',
          position: 'relative'
        }}
      >
        <Box>
          <Image
            duration={500}
            style={{ width: '90%' }}
            src="/static/media/img/svg/youwho-logos_text-logo-new-white-alt.svg"
          />
        </Box>
        <Typography variant="h4" color="rgba(0,0,0,0.75)" sx={{ fontWeight: '700', pt: '20px' }}>
          Global On Demand Services Platform
        </Typography>
        <Typography variant="h5" color="rgba(0,0,0,0.75)" sx={{ fontWeight: '400', pt: '20px' }}>
          Available on...
        </Typography>

        <Box sx={{ flexDirection: 'row' }}>
          <Button
            href={urls.android}
            target="_blank"
            size="large"
            sx={{ marginTop: '15px' }}
            color="white"
            variant="contained"
            startIcon={<IoLogoGooglePlaystore />}
          >
            Android
          </Button>
          <Button
            href={urls.ios}
            target="_blank"
            size="large"
            sx={{ marginLeft: '15px', marginTop: '15px' }}
            color="secondary"
            variant="contained"
            startIcon={<SiAppstore />}
          >
            iOS
          </Button>
          <Button
            href={urls.desktop}
            target="_blank"
            size="large"
            sx={{ marginLeft: '15px', marginTop: '15px' }}
            color="info"
            variant="contained"
            startIcon={<LuGlobe2 />}
            disabled
          >
            Web
          </Button>
        </Box>
      </Box>
      {/* <MainFooter /> */}
    </Box>
  );
}
