// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const YOUWHO_HOMEPAGE = 'https://why.youwho.io/';
export const YOUWHO_WHITEPAPER = 'https://wp.youwho.io/';

const ROOTS_ICO = '/';

// ----------------------------------------------------------------------

export const PATH_ICO = {
  root: ROOTS_ICO,
  overview: path(ROOTS_ICO, 'overview'),
  method: path(ROOTS_ICO, 'method'),
  invest: path(ROOTS_ICO, 'invest'),
  summary: path(ROOTS_ICO, 'summary')
};
