import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { isMobile, isIOS, isAndroid, deviceType } from 'react-device-detect';
//
import MainNavbar from './MainNavbar';
// import MainFooter from './MainFooter';
// import LandingPage from '../../pages/LandingPage';

const urls = {
  desktop: 'https://testapp.youwho.io',
  // desktop: 'http://localhost:3000',
  // desktop: 'https://why.youwho.io',
  ios: 'https://ios.youwho.io',
  android: 'https://android.youwho.io'
};

// ----------------------------------------------------------------------

export default function MainLayout() {
  const location = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (deviceType) {
        if (isMobile) {
          // console.log(deviceType);
          if (isIOS) {
            // console.log('ios');
            window.location.href = urls.ios + location.pathname;
          } else if (isAndroid) {
            // console.log('android');
            window.location.href = urls.android + location.pathname;
          } else {
            // console.log('else mobile');
            window.location.href = urls.desktop + location.pathname;
          }
        } else {
          // console.log(deviceType);
          // console.log('else non mobile');
          window.location.href = urls.desktop + location.pathname;
        }
      }
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [deviceType, location?.pathname]);

  return (
    <>
      <MainNavbar />
      {/* <LandingPage /> */}
      <Outlet />
      {/* <MainFooter /> */}
    </>
  );
}
