// react
import React from 'react';
// react-router
import { Routes, Route, Navigate } from 'react-router-dom';
// layouts
import HomePage from '../pages/HomePage';
import MainLayout from '../layouts/main';
// components
import LandingPage from '../pages/LandingPage';

export default function Router() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route element={<LandingPage title="App | YOUWHO" />}>
          <Route index path="/*" element={<HomePage />} />
        </Route>
      </Route>
    </Routes>
  );
}
